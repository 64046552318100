.menubar {
  background: #fff;
  border: 0 none;
  border-radius: 0;
  background-clip: padding-box;
  margin: 0;
  min-height: 50px;
  color: #262626;
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 99;
  padding: 0;
  .p-menuitem {
    &.p-menuitem-active {
      > .p-menuitem-link {
        background: #fff;
      }
    }
  }

  .p-menubar-root-list {
    > .p-menuitem.p-menuitem-active {
      > .p-menuitem-link {
        background: #fff;
        color: $b-cinza-antigo;
        &:not(.p-disabled):hover {
          background: #fff;
          color: $b-cinza-antigo;
          .p-menuitem-text, .p-menuitem-icon, .p-submenu-icon {
            color: $b-cinza-antigo;
          }
        }
        .p-menuitem-text, .p-menuitem-icon, .p-submenu-icon {
          color: $b-cinza-antigo;
        }
      }
    }
  }

  &.p-menubar {
    > .p-menuitem.p-menuitem-active {
      > .p-menuitem-link {
        background: #fff;
        color: $b-cinza-antigo;
        &:not(.p-disabled):hover {
          background: #fff;
          color: $b-cinza-antigo;
          .p-menuitem-text, .p-menuitem-icon, .p-submenu-icon {
            color: $b-cinza-antigo;
          }
        }
        .p-menuitem-text, .p-menuitem-icon, .p-submenu-icon {
          color: $b-cinza-antigo;
        }
      }
    }

    .p-button {
      color: #90a4ae !important;
      background: #fff !important;
      border: 0 none !important;
      border-radius: 0 !important;
      transition: none;
      height: 50px;

      &:is(:hover, :focus, :active) {
        background-color: #8BC34A !important;
        color: #fff !important;
        border: 0 none !important;
        box-shadow: none !important;
        border-radius: 0 !important;
      }
    }

  }

  .p-menuitem-link {
    font-size: 0.875em;
    padding-left: 18px !important;
    padding-right: 18px !important;
    color: #484848;
    border: none;
    border-radius: 0;
    background-clip: padding-box;
    cursor: pointer;
    height: 50px !important;

    &:is(:hover, :focus, :active) {
      background-color: #8BC34A !important;
      color: #fff !important;
      box-shadow: none !important;
      border-radius: 0 !important;
    }
  }

  .submenu-item {
    > .p-menuitem-link {
      &:is(:hover, :focus, :active) {
        background-color: #f6f6f6 !important;
        color: black !important;
        > .p-menuitem-text {
          color: black !important;
        }
        > .p-menuitem-icon {
          color: black !important;
        }
      }
    }
  }

}

.p-menuitem-text:is(:hover, :focus, :active) {
  color: #fff !important;
}

.p-menuitem-icon:is(:hover, :focus, :active) {
  color: #fff !important;
}

.p-menuitem-link:is(:hover, :focus, :active) > .p-menuitem-text {
  color: #fff !important;
}

.p-menuitem-link:is(:hover, :focus, :active) >.p-menuitem-icon {
  color: #fff !important;
}

.p-submenu-icon:is(:hover, :focus, :active) {
  color: #fff !important;
}

.p-menuitem-link:is(:hover, :focus, :active) >.p-submenu-icon {
  color: #fff !important;
}

.p-center .p-menuitem-icon {
  margin: 0 !important;
  padding: 0 !important;
}