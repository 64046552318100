.generic-summary-totalizer {
  font-size: 12px;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 48px;
  
  .info-icon {
    margin-bottom: -5px;
  }
  
  td,
  th {
    white-space: nowrap;
    text-align: center;
    padding: 8px;
    &.table-upper-head-no-border {
      border: none;
    } 
  }
  
  tr {
    &.table-upper-head-container {
      border: none;
    }
  }
  
  tbody {
    color: #212121;
    td {
      border-bottom: 1px dashed #D1D4D6;
      border-left: 1px dashed #D1D4D6;
      border-right: 1px dashed #D1D4D6;
    }
  }
}
