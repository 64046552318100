.MuiChip-root {
  border-radius: 32px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #f2f2f2;
  &.MuiChip-filled {
    border: 1px solid rgb(0, 0, 0, 0.08) !important;
  }
  .MuiChip-label {
    color: $b-cinza-icons;
  }
  .MuiChip-deleteIcon {
    color: #607d8b !important;
  }
  .transform-icon-change-in {
    transform: rotate(45deg);
    transition: transform 0.2s ease-in-out;
  }
  .transform-icon-change-out {
    transform: rotate(-45deg);
    transition: transform 0.2s ease-in-out;
  }
}