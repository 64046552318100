.select-custom {
  height: 34px;
  border: solid 2px #e7ebee;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif !important;
  line-height: 28px !important;
  font-size: 14px !important;
  &.MuiInputBase-root {
    &:hover {
      border-bottom: solid 2px #e7ebee;
    }
    &::before {
      border: none !important;
      &:hover:active:focus {
        border: none !important;
      }
      &:hover:not(.Mui-disabled, .Mui-error)::before {
        border-bottom: solid 2px #e7ebee;
      }
    }
    &::after {
      border: none !important;
    }
    &:hover:not(.Mui-disabled, .Mui-error)::before {
      border-bottom: solid 2px #e7ebee;
    }
  }
  .MuiSelect-select {
    background-color: #fff;
    text-overflow: ellipsis;
    height: 22px;
    padding: 0 10px;
    font-size: 13px;
    font-style: normal;
    letter-spacing: normal;
    height: 30px !important;
    em {
      font-style: normal;
      font-family: 'Open Sans', sans-serif !important;
      line-height: 28px;
      font-size: 14px;
      color: #444;
    }
    &:focus {
      background-color: #fff;
      &::before {
        border: solid 2px #e7ebee;
        &:hover:active:focus {
          border: solid 2px #e7ebee;
        }
      }
      &::after {
        border: none !important;
      }
    }
    &::before {
      border: none !important;
      &:hover:active:focus {
        border: none !important;
      }
    }
    &::after {
      border: none !important;
    }
  }
  &::before {
    border: none !important;
    &:hover:active:focus {
      border: none !important;
    }
  }
  &::after {
    border: none !important;
  }
  &.Mui-focused {
    border: solid 2px #e7ebee;
  }
  &.invalid {
    border-color: #9D2835 !important;
  }
}

.select-custom_date {
  cursor: pointer;
  font-style: normal;
  font-family: 'Open Sans', sans-serif !important;
  line-height: 28px;
  font-size: 14px;
  color: #444;
  height: 30px;
  padding: 0 10px 0 10px;
  border: solid 2px #e7ebee;
  border-radius: 4px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  width: calc(100% - 24px);
  text-overflow: ellipsis;
  &.invalid {
    border-color: #9D2835 !important;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

// .react-datepicker__input-container::before {
//   pointer-events: none;
//   border-style: solid;
//   border-width: 2px 2px 0 0;
//   content: "";
//   display: inline-block;
//   height: 7px;
//   right: 14px;
//   position: absolute;
//   top: 11px;
//   transform: rotate(135deg);
//   vertical-align: top;
//   width: 7px;
//   color: rgba(0, 0, 0, 0.54);
// }

.select-custom_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 32px;
  border: solid 2px #e7ebee;
  background-color: #fff;
  border-right: none;
  padding-left: 1px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  cursor: pointer;
}

.react-datepicker-popper {
  padding-top: 0 !important;
  .react-datepicker {
    background: #FFFFFF;
    box-shadow: 0px 1px 6px rgba(11, 60, 93, 0.12), 0px 4px 18px 3px rgba(46, 50, 52, 0.08);
    border-radius: 4px;
    border: none;
    .react-datepicker__triangle {
      display: none;
    }
    .react-datepicker__header {
      background-color: #FFFFFF;
      border-bottom: none;
    }
    .react-datepicker__month-container {
      width: 100%;
      height: 100%;
      .react-datepicker__month-text {
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        width: 90px;
        height: 46px;
        text-align: center;
        line-height: 46px;
        border-radius: 4px;
        text-transform: capitalize;
        &:hover {
          background: #07886e;
        }
        &.react-datepicker__month-text--keyboard-selected {
          color: #000000DE;
          background: #FFFFFF;
          &:hover {
            background: #07886e;
          }
        }
        &.react-datepicker__month-text--in-selecting-range {
          background: #084337;
          color: #FFFFFF;
          &:hover {
            background: #084337;
            color: #FFFFFF;
          }
        }
        &.react-datepicker__month-text--disabled {
          color: #a0a0a0;
          background-color: #FFFFFF;
          &:hover {
            background: #FFFFFF;
            color: #a0a0a0;
          }
        }
      }
      .react-datepicker__month {
        .react-datepicker__week {
          .react-datepicker__day {
            width: 36px;
            height: 36px;
            text-align: center;
            line-height: 40px;
            border-radius: 4px;
            text-transform: capitalize;
            &:hover {
              background: #084337;
              color: #FFFFFF;
            }
            &.react-datepicker__day--keyboard-selected {
              color: #000000DE;
              background: #FFFFFF;
              &:hover {
                background: #084337;
                color: #FFFFFF;
              }
            }
            &.react-datepicker__day--in-selecting-range {
              background: #084337;
              color: #FFFFFF;
              &:hover {
                background: #084337;
                color: #FFFFFF;
              }
            }
            &.react-datepicker__day--disabled {
              color: #FFFFFF;
              background-color: #FFFFFF;
              &:hover {
                background: #FFFFFF;
              }
            }
            &.react-datepicker__day--outside-month {
              background-color: #FFFFFF;
              color: #a0a0a0 !important;
            }
          }
        }
      }
    }
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-evenly;
  }

}