$p-brand-01:#005686;
$p-brand-02:#9BC21B;
$p-light:#ffffff;
$p-dark:#667C89;

$s-verde:#1ABC9C;
$s-verde-base :#97C05C;
$s-verde-borda-botao:#607E38;
$s-verde-portal:#57B99D;
$s-verde-escuro:#084337;

$b-cinza-texto:#212121;
$b-cinza-texto-modal:#505050;
$b-cinza-icons:#757575;
$b-bg-botao:#94A3AD;
$b-cinza-bg-modais:#EFEFEF;
$b-cinza-borda-input:#E8EBEE;
$b-fieldset-modais:#F1F1F1;
$b-fundo-filtro:#EFEDEB;
$b-cinza-transp:#C4C5C6;
$b-cinza-antigo:#90a4ae;
$b-cinza-fundo:#f9f9f9;
$b-cinza-grafico: #f7f7f7;

$o-erro:#C4C5C6;
$o-sucesso:#57B99D;
$o-chip-azul:#73B4DB;
$o-chip-roxo:#EFEDEB;
$o-vermelho-toast:#FBEEEC;
$o-verde-toast:#E2EFDA;
$o-verde-range:#F4FFFC;
$o-link:#1E549B;
$o-portal-novidades:#48A7EE;