h1,
h2,
h3,
h4,
h5,
em,
h6 {
    font-style: normal;
    font-family: 'Open Sans', sans-serif;
}

h1 {
    font-weight: 300;
    font-size: 96px;
}

h2 {
    font-weight: 300;
    font-size: 60px;
}

h3 {
    font-weight: 400;
    font-size: 48px;
}

h4 {
    font-weight: 400;
    font-size: 34px;
}

h5 {
    font-weight: 400;
    font-size: 24px ;
}

h6 {
    font-weight: 400;
    font-size: 20px;
}

p {
    font-weight: 400;
    font-size: 16px;
}

caption {
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
}