.btn-primario, .btn-secundario, .btn-terciario, .btn-alerta {
  align-items: flex-start;
  border-style: solid;
  border-radius: 3px !important;
  border-width: 0.5px;
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  gap: 4px;
  letter-spacing: normal;
  line-height: 1.54;
  margin: 10px 10px 10px 0;
  min-inline-size: max-content;
  padding: 4px 11px;
  text-align: center;
  text-decoration: none;
  text-indent: 0px;
  text-rendering: auto;
  text-shadow: none;
  user-select: none;
  vertical-align: middle;
  word-spacing: normal;
	text-transform: capitalize !important;
}

.btn-primario.sm, .btn-secundario.sm, .btn-terciario.sm, .btn-alerta.sm {
	margin: 0;
}

.btn-primario.smt, .btn-secundario.smt, .btn-terciario.smt, .btn-alerta.smt {
	margin-top: 0;
}

.btn-primario.smb, .btn-secundario.smb, .btn-terciario.smb, .btn-alerta.smb {
	margin-bottom: 0;
}

.btn-primario.smr, .btn-secundario.smr, .btn-terciario.smr, .btn-alerta.smr {
	margin-right: 0;
}

.btn-primario.sml, .btn-secundario.sml, .btn-terciario.sml, .btn-alerta.sml {
	margin-left: 0;
}

.btn-primario {
	background-color: #0b3c56 !important;
	border: 1px #48a2cf solid;
	color: #ffffff !important;
	&.btn-voltar {
		text-transform: none !important;
	}
	&.max-width {
		width: 100%;
	}
}

.btn-secundario {
	background-color: #ffffff !important;
	border-color: #0b3c56;
	color: #0b3c56 !important;
	&.btn-voltar {
		text-transform: none !important;
	}
}

.btn-terciario {
	background-color: #e4e4e4 !important;
	border-color: #e9e9e9;
	color: #607d8b !important;
}

.btn-alerta {
	background-color: red !important;
	border-color: #C70039;
	color: #ffffff !important;
}

.btn-secundario:focus:not(:disabled), 
.btn-primario:focus:not(:disabled),
.btn-terciario:focus:not(:disabled),
.btn-alerta:focus:not(:disabled) {
	text-decoration: none;
}

.btn-primario:hover:not(:disabled),
.btn-secundario:hover:not(:disabled), 
.btn-terciario:hover:not(:disabled),
.btn-alert:hover:not(:disabled) {
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
	text-decoration: none;
	transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
}

.btn-primario:hover:not(:disabled) {
	background-color: #062637 !important;
}

.btn-secundario:hover:not(:disabled) {
	background-color: #efefef !important;
}

.btn-terciario:hover:not(:disabled) {
	background-color: #f0f0f0 !important;
}

.btn-alerta:hover:not(:disabled) {
	background-color: #FF4922 !important;
}

.btn-primario:active:not(:disabled),
.btn-secundario:active:not(:disabled),
.btn-terciario:active:not(:disabled),
.btn-alerta:active:not(:disabled) {
	box-shadow: none;
	transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
}

.btn-primario:active:not(:disabled) {
	background-color: #3279a0 !important;
}

.btn-secundario:active:not(:disabled) {
	background-color: #6e8996 !important;
}

.btn-terciario:active:not(:disabled) {
	background-color: #d8d8d8 !important;
}

.btn-alert:active:not(:disabled) {
	background-color: lightsalmon !important;
    border-color: lightgray !important;
}

.btn-primario:disabled,
.btn-secundario:disabled,
.btn-terciario:disabled,
.btn-alerta:disabled {
	cursor: not-allowed;
	opacity: 0.3;
}

.btn-print-small {
  padding: 6px !important;
	min-width: 36px !important;
}

.btn-group.open .dropdown-toggle {
	border-radius: 50%;
    display: block;
}

.MuiIconButton-root {
	&.button-settings {
		width: 36px;
		height: 36px;
		color: #FFFFFF !important;
		border-radius: 3px !important;
		border: 1px solid var(--farm-tell-farm-tell-azul-2, #48A2CF) !important;
		background: var(--farm-tell-farm-tell-azul, #0B3C56) !important;
	}
}

.two-options-switch {
	.MuiSwitch-track {
		background-color: #9EB1BB !important;
		opacity: 0.5 !important;
	}
	.MuiSwitch-thumb {
		color: #0B3C56;
	}
}
