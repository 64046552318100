.MuiAccordion-root {
  box-shadow: none !important;
  min-height: 70px;
  .MuiAccordionSummary-root {
    min-height: 55px;
    margin-bottom: 10px;
    &.Mui-expanded {
      min-height: 55px;
    }
  }
  .MuiAccordionSummary-expandIconWrapper {
    padding-top: 20px;
    transform: none;
    &.Mui-expanded {
      transform: none;
    }
  }
  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    align-self: end;
    margin: 0;
    &.Mui-expanded {
      margin: 0;
    }
  }
  .MuiAccordionDetails-root {
    padding: 0;
  }
}