.input-custom {
  width: 100%;
  border: solid 2px #e7ebee;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif !important;
  line-height: 28px !important;
  font-size: 14px !important;
  &.MuiInputBase-root {
    &:hover {
      border-bottom: solid 2px #e7ebee;
    }
    &::before {
      border: none !important;
      &:hover:active:focus {
        border: none !important;
      }
      &:hover:not(.Mui-disabled, .Mui-error)::before {
        border-bottom: solid 2px #e7ebee;
      }
    }
    &::after {
      border: none !important;
    }
    &:hover:not(.Mui-disabled, .Mui-error)::before {
      border-bottom: solid 2px #e7ebee;
    }
  }
  .MuiInput-input {
    background-color: #fff !important;
    height: 22px;
    padding: 0 10px;
    font-size: 13px;
    font-style: normal;
    letter-spacing: normal;
    height: 30px !important;
    em {
      font-style: normal;
      font-family: 'Open Sans', sans-serif !important;
      line-height: 28px;
      font-size: 14px;
      color: #444;
    }
    &:focus {
      background-color: #fff;
      &::before {
        border: solid 2px #e7ebee;
        &:hover:active:focus {
          border: solid 2px #e7ebee;
        }
      }
      &::after {
        border: none !important;
      }
    }
    &::before {
      border: none !important;
      &:hover:active:focus {
        border: none !important;
      }
    }
    &::after {
      border: none !important;
    }
  }
  &::before {
    border: none !important;
    &:hover:active:focus {
      border: none !important;
    }
  }
  &::after {
    border: none !important;
  }
  &.Mui-focused {
    border: solid 2px #e7ebee;
  }
  &.invalid {
    border-color: #9D2835  !important;
  }
}

.item-qtd {
  input {
    padding-left: 10px !important;
    width: calc(100% - 10px) !important;
  }
}

.input-label {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #444;
}
